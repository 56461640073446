import { logger } from '@tunasong/models'
import { Enumerations, Message } from 'webmidi'
import type { MessageEvent } from 'webmidi'
import { BTDecode } from './bt-decoder.js'
import { EventEmitter } from '@tunasong/ui-lib'
import { BtMidiInputChannelAdapter } from './input-channel-adapter.js'

/** Adapted from https://github.com/BLE-MIDI/WebMIDI/blob/main/midiBLEdecoder.js */
export class BTMidiInputAdapter extends EventEmitter<'midimessage' | 'noteon' | 'noteoff'> {
  channels: BtMidiInputChannelAdapter[] = []

  constructor(
    private device: BluetoothDevice,
    private characteristic: BluetoothRemoteGATTCharacteristic
  ) {
    super()
    logger.debug('Creating BT MIDI input adapter', this.device)

    // @note this is 1-indexed so you can get channel by number
    for (const channel of Enumerations.MIDI_CHANNEL_NUMBERS) {
      const adapter = new BtMidiInputChannelAdapter(this, channel, characteristic)
      this.channels[channel] = adapter
    }
    characteristic.addEventListener('characteristicvaluechanged', this.onBTMidiMessageReceived)
    characteristic.startNotifications()
  }

  get deviceId() {
    return this.device.id
  }
  get name() {
    return this.device.name || 'Unnamed MIDI Device'
  }

  destroy() {
    logger.debug('Destroying BT MIDI input adapter', this.device)
    this.channels.forEach(channel => channel.destroy())
    this.characteristic.removeEventListener('characteristicvaluechanged', this.onBTMidiMessageReceived)
  }

  private onBTMidiMessageReceived = (event: Event) => {
    const characteristic = event.target as BluetoothRemoteGATTCharacteristic
    const buffer = characteristic.value?.buffer
    const data = buffer ? new Uint8Array(buffer) : null

    if (!data) {
      return
    }

    const decoder = new BTDecode(data)
    if (!decoder.midiMessage) {
      logger.warn('Incomplete MIDI message', data)
      return
    }

    const msg = new Message(decoder.midiMessage)

    const channel = this.channels[msg.channel]
    /** @todo hack to interoperate with webmidi */
    const msgEvent: MessageEvent = {
      message: msg,
      port: this as never,
      timestamp: Date.now(),
      target: this as never,
      type: msg.type,
    }

    if (msg.isChannelMessage) {
      channel.handleMessage(msgEvent)
    }

    this.emit('midimessage', msgEvent)
  }
}
