import { Box } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import type { AudioEvent, Track } from '@tunasong/schemas'
import { Pool } from '@tunasong/ui-lib'
import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useMic, useTransport } from '../hooks/index.js'
import { Transport } from '../waveform/transport.js'
import { useDAW } from './hooks/daw.js'
import { TrackView } from './track-view.js'
import { getTracksDuration } from './util/track.js'
export interface TrackComponent {
  track: Track
}
export interface DAWProps {
  className?: string

  parentId: string
  events?: AudioEvent[]
}

export const DAW: FC<DAWProps> = props => {
  const { parentId, events = [] } = props

  const { transport } = useTransport()

  const spacing = 8
  const headerHeight = spacing * 6

  const { entity } = graphHooks.useEntity(parentId)

  const { start: startMic, stop: stopMic, ready: micReady } = useMic()
  useEffect(() => {
    if (!micReady) {
      return
    }
    startMic()
    return () => {
      stopMic()
    }
  }, [micReady, startMic, stopMic])

  /** DAW */
  const {
    tracks = [],
    hasArmedTracks,
    onUpdateTrack,
    onCreateTrack,
    onDeleteTrack,
    onStartRecord,
    onStopRecord,
  } = useDAW({
    parentId,
  })

  const duration = getTracksDuration(tracks)
  const [showPool, setShowPool] = useState(false)

  return (
    <>
      <TrackView
        tracks={tracks}
        events={events}
        headerHeight={headerHeight}
        onDeleteTrack={onDeleteTrack}
        onCreateTrack={onCreateTrack}
        duration={duration}
        onUpdateTrack={onUpdateTrack}
        onTogglePool={() => setShowPool(p => !p)}
      />
      {showPool && <Pool entity={entity} />}
      <Box sx={{ flex: 1 }} />

      <Transport
        transport={transport}
        enableRecord={hasArmedTracks}
        onStartRecord={onStartRecord}
        onStopRecord={onStopRecord}
      />
    </>
  )
}
