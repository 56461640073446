import { graphHooks } from '@tunasong/graph-lib/react'
import type { AudioChannel } from '@tunasong/models'
import type { AudioClip, Clip } from '@tunasong/schemas'
import { isAudio, isAudioClip, isEntityClip } from '@tunasong/schemas'
import { useEffect, useState } from 'react'
import { ClipPlayer } from '../engine/clip-player.js'

/** Schdule the clip event on the timeline using the ClipPlayer */
export const useClipPlayer = (channel: AudioChannel, clip: Clip) => {
  /** If this is an entityClip we'll retrieve the entity here */
  const entityId = isEntityClip(clip) ? clip.entityId : null
  const { entity } = graphHooks.useEntity(entityId)

  const entityUrl = entity?.storageUrls?.url

  const [url, setUrl] = useState<string>()
  useEffect(() => {
    if (isAudioClip(clip)) {
      setUrl(clip.url)
    } else if (isEntityClip(clip) && isAudio(entity)) {
      setUrl(entityUrl)
    }
  }, [clip, entity, entityUrl])

  useEffect(() => {
    if (!(channel && url)) {
      return
    }
    const audioClip: AudioClip = {
      ...clip,
      type: 'audioclip',
      url,
    }
    const player = new ClipPlayer({ clip: audioClip, track: channel })

    return () => {
      player.disconnect()
      player.dispose()
    }
  }, [channel, clip, url])
}
