import { isEntityClip } from '@tunasong/schemas'
import type { AudioMedia } from '@tunasong/schemas'
import cn from 'classnames'
import type { Ref } from 'react'
import { usePeaks } from '../../hooks/index.js'
import { Wave } from '../../waveform/wave.js'
import type { ClipProps } from './clip-container.js'
import { useStyles } from './clip.styles.js'
import { graphHooks } from '@tunasong/graph-lib/react'

export interface EntityClipProps extends ClipProps {
  className?: string
  ref?: Ref<HTMLDivElement>
}

const EntityClip = (props: EntityClipProps) => {
  const { className, clip, ref } = props
  const { classes } = useStyles()

  if (!isEntityClip(clip)) {
    throw new Error(`EntityClip component used for different clip type ${clip.type}`)
  }

  const { entity: media } = graphHooks.useEntity<AudioMedia>(clip.entityId)

  const { peaks } = usePeaks({ media, renderPeaks: true })

  if (!clip) {
    return null
  }

  return (
    <div className={cn(className, classes.clip)} ref={ref}>
      <Wave className={classes.waveContainer} peaks={peaks} position={0} />
    </div>
  )
}

export default EntityClip
