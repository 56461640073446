import {
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import type { ListItemButtonProps } from '@mui/material'
import { graphHooks } from '@tunasong/graph-lib/react'
import { Audio as AudioIcon, Pause, Play, Song } from '@tunasong/icons'
import { dayjs } from '@tunasong/models'
import type { Entity, Media, Persisted } from '@tunasong/schemas'
import { EntityIcon, PulseProgress, useNavigateToEntity } from '@tunasong/ui-lib'
import { useCallback, useEffect, useState } from 'react'
import type { FC, MouseEvent } from 'react'
import invariant from 'tiny-invariant'

export interface PlayListItemProps extends ListItemButtonProps {
  entity: Persisted<Media>
  onPlayClicked?: (ev: MouseEvent) => void
  playing?: boolean
  progressLabel?: string
  showUpdated?: boolean
  showPlayButton?: boolean
}

export const PlayListItem: FC<PlayListItemProps> = props => {
  const {
    entity,
    progressLabel,
    playing = false,
    onPlayClicked,
    showPlayButton = false,
    showUpdated = false,
    ...restProps
  } = props

  const { getBacklinks } = graphHooks.useEntityGraph()

  const navigateToEntity = useNavigateToEntity()
  const handleNavigate = useCallback(
    (entityOrEntityId?: Persisted<Entity> | string) => (ev: MouseEvent) => {
      const entityId = typeof entityOrEntityId === 'string' ? entityOrEntityId : entityOrEntityId?.id
      if (entityId) {
        const navigationMode = ev.shiftKey ? 'dialog' : 'page'
        navigateToEntity({ id: entityId }, { navigationMode })
      }
    },
    [navigateToEntity]
  )

  // Get the backlinks for the entities in the playlist (key) to song/page that hasRecording (value)
  const [backlinks, setBacklinks] = useState<Map<string, string>>()
  useEffect(() => {
    invariant(entity?.id, 'Entity ID is required')
    const links = new Map<string, string>()
    getBacklinks(entity.id).then(backlinks => {
      for (const edge of backlinks) {
        // we are looking for 'hasRecording' backlinks
        if (edge.relation !== 'hasRecording') {
          continue
        }
        links.set(edge.target, edge.source)
      }
      setBacklinks(links)
    })
  }, [entity?.id, getBacklinks])

  const secondary = showUpdated ? `Updated ${dayjs(entity.updatedAt).fromNow()}` : undefined

  return (
    <ListItemButton
      sx={{
        cursor: 'pointer',
      }}
      {...restProps}
    >
      <ListItemIcon>
        <EntityIcon entity={entity} size={'medium'} />
      </ListItemIcon>
      <ListItemText primary={entity.name} secondary={secondary} />
      <ListItemSecondaryAction>
        {showPlayButton && (
          <IconButton onClick={onPlayClicked} title="Toggle play">
            {playing ? (
              <>
                <Typography variant="caption">{progressLabel}</Typography>
                <PulseProgress size="small" />
                <Pause />
              </>
            ) : (
              <Play />
            )}
          </IconButton>
        )}
        <IconButton onClick={handleNavigate(entity)} title="Go to audio clip">
          <AudioIcon />
        </IconButton>

        <IconButton
          disabled={!backlinks?.has(entity.id)}
          onClick={handleNavigate(backlinks?.get(entity.id))}
          title="Go to song/page"
        >
          <Song />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}
