import * as React from 'react'

import { Button } from '@mui/material'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid'
import type { GridColDef, GridEventListener, GridRowId, GridRowModesModel, GridRowsProp } from '@mui/x-data-grid'
import { Add, Cancel, Delete, Play, Save } from '@tunasong/icons'
import { shortUuid } from '@tunasong/models'
import type { MidiSpec } from '@tunasong/schemas'
import { useMidi } from './midi.hook.js'

interface EditToolbarProps {
  channel?: number
  onChannelChange: (channel: number) => void
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props

  const handleClick = () => {
    const id = shortUuid()
    setRows(oldRows => [...oldRows, { id, name: '', pc: 0, bank: 0, isNew: true }])
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<Add />} onClick={handleClick}>
        Add Program
      </Button>
    </GridToolbarContainer>
  )
}

const toRows = (midi: MidiSpec) =>
  midi.programs.map((program, index) => ({
    id: index.toString(),
    name: program.name,
    pc: program.pc,
    bank: program.bank !== undefined ? Number(program.bank) : undefined,
    bankLsb: program.bankLsb !== undefined ? Number(program.bankLsb) : undefined,
    isNew: false,
  }))

type Row = ReturnType<typeof toRows>[number]

export interface MidiGridProps {
  /** use the specified MIDI channel. If not specified, use Omni (all channels) */
  channel?: number
  spec: MidiSpec
  onChange(spec: MidiSpec): void
  onChannelChange?(channel: number): void
}

export function MidiPCEditor({ spec, onChange, channel, onChannelChange }: MidiGridProps) {
  const [rows, setRows] = React.useState(toRows(spec))
  const { sendProgramChange } = useMidi({ channel })
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handlePlayClick = (id: GridRowId) => () => {
    const row = rows.find(row => row.id === id)
    if (!row) {
      return
    }
    sendProgramChange({ program: row.pc, patchBank: row.bank, patchBankLsb: row.bankLsb })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedRows = rows.filter(row => row.id !== id)
    setRows(updatedRows)
    saveRows(updatedRows)
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow?.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const saveRows = (rows: Row[]) => {
    onChange({
      ...spec,
      programs: rows.map(row => ({
        name: row.name,
        pc: Number(row.pc),
        bank: row.bank !== undefined ? Number(row.bank) : undefined,
        bankLsb: row.bankLsb !== undefined ? Number(row.bankLsb) : undefined,
      })),
    })
  }

  const processRowUpdate = (newRow: Row) => {
    const updatedRow = { ...newRow, isNew: false }
    const updatedRows = rows.map(row => (row.id === newRow.id ? updatedRow : row))
    saveRows(rows)
    setRows(updatedRows)
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', minWidth: 250, editable: true },
    {
      field: 'pc',
      headerName: 'Program Change',
      type: 'number',
      minWidth: 100,
      editable: true,
    },
    {
      field: 'bank',
      headerName: 'Bank (CC: 0)',
      type: 'number',
      editable: true,
    },
    {
      field: 'bankLsb',
      headerName: 'Bank LSB (CC: 32)',
      type: 'number',
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem icon={<Play />} label="Play" onClick={handlePlayClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<Delete />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
        ]
      },
    },
  ]

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      slots={{
        toolbar: EditToolbar as never,
      }}
      slotProps={{
        toolbar: { setRows, setRowModesModel, onChannelChange, channel } as never,
      }}
    />
  )
}
