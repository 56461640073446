import { EventEmitter } from '@tunasong/ui-lib'

// Bluetooth MIDI Output Adapter
export class BtMidiOutputChannelAdapter extends EventEmitter {
  constructor(
    output: unknown,
    channel: number,
    private midiChar: BluetoothRemoteGATTCharacteristic
  ) {
    super()
  }
}
